/* GENERAL */

.is-image {
  border: 1px solid #7a7a7a !important;
}

.progress {
  height: 0.5rem !important;
}

.disable-scroll {
  overflow: hidden !important;
}

/* HEAD */
.navbar-brand > a:hover {
  background-color: unset !important;
}

.is-menu a:hover {
  color: #000 !important;
}

/* LOAD */

.is-loading {
  border: none !important;
  font-size: 5rem !important;
}

/* HERO */

.is-hero .is-wrapper {
  margin: auto;
  padding-top: 4rem !important;
  max-width: 1200px !important;
}

.is-hero .is-wrapper-small {
  margin: auto;
  padding-top: 4rem !important;
  max-width: 800px !important;
}

.is-hero .is-wrapper .hero-head {
  padding: 0 1.5rem;
}

/* WELCOME */
#welcome {
  background: linear-gradient(rgba(255, 255, 255), rgba(255, 255, 255, 0.5)),
    url("../images/background.jpg") center center / cover, no-repeat;
}

/* PROJECT */

.is-project {
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0 0 1px rgb(10 10 10 / 2%);
}

.is-project:not(:last-child) {
  margin-bottom: 3rem !important;
}

/* ABOUT */

#about .card {
  height: 100% !important;
}

/* CONTACT */

#contact .is-contacts {
  padding-top: 3rem !important;
}

/* MODAL */

.is-modal .modal-background {
  opacity: 0.75;
}

.is-modal .card {
  height: 100% !important;
  overflow: scroll;
}

.is-modal .delete {
  background: none;
  position: absolute;
  z-index: 50;
  top: 0;
  right: 0;
}

.is-modal .delete::after,
.is-modal .delete::before {
  background: #000 !important;
}

/* FOOTER */

.foot-links .foot-link {
  border: none !important;
}

.foot-links .foot-link:hover {
  background-color: unset !important;
  color: #7a7a7a !important;
}

/* MEDIA QUERIES */

@media (min-width: 769px) {
  #projects .is-project:nth-child(even) .columns {
    flex-direction: row-reverse !important;
  }
}
